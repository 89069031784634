import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Tag, Select, Switch, Popover , Badge, message } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined, ExclamationCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import Logo from './Logo';
import NavNotification from './NavNotification';
import NavProfile from './NavProfile';
import NavLanguage from './NavLanguage';
import NavPanel from './NavPanel';
import NavSearch from './NavSearch';
import SearchInput from './NavSearch/SearchInput.js'
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils';
import { db, auth } from 'auth/FirebaseAuth';
import { useHistory, useParams } from 'react-router-dom';
import { Detector } from "react-detect-offline";


const { Header } = Layout;

const onlineStatus = (
  <>
    <h4><span style={{ marginRight: '5px' }}><CheckCircleFilled style={{ color: '#87d068' }} /></span>Internet Status</h4>
    <p>You Internet Connection is Stable.</p>
  </>
);

const offlineStatus = (
  <>
    <h4><span style={{ marginRight: '5px' }}><ExclamationCircleFilled style={{ color: '#f50' }} /></span>Internet Status</h4>
    <p>Unstable Internet Connection Detected</p>
  </>
);


export const HeaderNav = (props) => {
  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme, direction } = props;
  const [searchActive, setSearchActive] = useState(false)
  const [acc, setacc] = useState([])
  const [ca, setca] = useState([])
  const [opt, setopt] = useState([])

  var toggle; //to set demo toggle
  var userData = JSON.parse(localStorage.getItem('userData'));

  if (userData != null) {
    toggle = userData.liveMode;
  }
  else {
    var userInterval = 0;
    userInterval = setInterval(() => {
      userData = JSON.parse(localStorage.getItem('userData'));
      if (userData != null) {
        clearInterval(userInterval);
        window.location.reload()
      }
      else {
        console.log('nope not yet')
      }
    }, 1000)
  }


  const onSearchClose = () => {
    setSearchActive(false)
  }
  const accounts = []

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }
  async function getUser() {
    const data = await db.collection('users').doc(localStorage.getItem('auth_token')).get();
    // auth.onAuthStateChanged((user)=>{
    //     if (user) {
    //       console.log(user.uid);
    //       this.setState({
    //         uid:user.uid
    //       })
    //     } else {
    //       console.log('no user');
    //     }
    //   });
    // console.log(data,"data")

    // console.log(dat.data()?.accounts)




    // setopt(data.data().accounts)
    // console.log(data.data())

    // Object.keys(opt).map(element => {
    //   accounts.push(element)
    // })
    // setacc(accounts)

    // console.log(acc, 'accc')


  }

  useEffect(() => {

    // //getting live mode preference from user document
    // db.collection('users').doc(localStorage.getItem('auth_token')).get()
    //   .then((query) => {
    //     var data = query.data();
    //     toggle = data.liveMode 
    //   })
    //   .catch((error) => {
    //     console.log('error getting live mode preference from user document : ', error);
    //   })
    //   // .finally(() => {
    //   //   setDemoToggle(toggle);
    //   // })


    getUser()
    if (!isMobile) {
      onSearchClose()
    }

    // //run every 5 seconds
    // var interval = setInterval(() => {

    // }, 5000);
    

  }, [])



  const [demoToggle, setDemoToggle] = useState(toggle);
  // const [reload, setReload] = useState(false);


  const { label, value, closable, onClose } = props;
  // console.log(accounts,"fff",ca,'cc')

  // const handleReload = () => {
  //   if (reload == false) 
  //     setReload(true);
  //   else 
  //     setReload(false);
  // }

  let navigate = useHistory();
  const changeDemoToggle = (checked) => {
    var params = window.location.href.split('/app/')[1];
    console.log('DEMO TOGGLE CHANGED : ', checked);
    db.collection('users').doc(localStorage.getItem('auth_token')).update({ liveMode: checked })
      .then(() => {
        console.log('live mode got updated to ', checked)
        if (checked == false) {
          if (params.includes('demo')) {
            navigate.push('/app/' + params.substring(5))
          }
          else {
            navigate.push('/app/demo/' + params)
          }
          window.location.reload();
        }
        else {
          if (params.includes('demo')) {
            navigate.push('/app/' + params.substring(5))
          }
          else {
            navigate.push('/app/' + params)
          }
          window.location.reload();
        }

      })
      .catch(() => { console.log('error updating live mode of user document') })
      .finally(() => {
        setDemoToggle(checked);
      })
    var obj = {
      accounts: userData.accounts,
      displayName: userData.displayName,
      email: userData.email,
      liveMode: checked,
      phoneNumber: userData.phoneNumber,
      photoUrl: userData.photoUrl,
      providerId: userData.providerId,
      uid: userData.uid
    }
    localStorage.setItem('userData', JSON.stringify(obj));

    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);
  }

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {
                isNavTop && !isMobile ?
                  null
                  :
                  <li className="ant-menu-item ant-menu-item-only-child" onClick={() => { onToggle() }}>
                    {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                  </li>
              }


            </ul>
          </div>
          {/* <div className="nav mt-3" style={{ width: '100%'}}>
            {demoToggle == false ? 
              <div style={{width: '100%', textAlign: 'center', marginBottom: '10px'}}>
              <Tag color="yellow">Demo</Tag>
            </div>
            :
            <></>  
          }
          </div> */}
            <div className="nav-right">
            <Detector //onChange={(online)=>{if(online){ message.success({content:'You are back online ! 😃',key: 'internetpopup'}) }else{ message.error({content:'You are offline now! 🥺',key: 'internetpopup'}) } }}
              render={({ online }) => (
                <>
                <Popover placement="bottom" content={online?onlineStatus:offlineStatus}>
                  <Badge status="processing" color={online?'#87d068':'#f50'} />
                </Popover>
                </>
              )}
            />
              
              {/* <Popover placement="bottom" content={helpContent}>
                <Switch
                  className="mt-4"
                  style={{ minWidth: 'fit-content' }}
                  checkedChildren='Live On'
                  unCheckedChildren='Live Off'
                  defaultChecked={demoToggle}
                  onChange={changeDemoToggle}
                />
              </Popover> */}

              <NavNotification />
              {/* <NavLanguage /> */}
              <NavPanel direction={direction} />
              <NavProfile />
            </div>
          {/* <NavSearch active={searchActive} close={onSearchClose} /> */}
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction }
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav);