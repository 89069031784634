import React, { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Spin, notification } from 'antd';
import Icon from '@ant-design/icons';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, siabullsUserData } from 'configs/AppConfig';
import { db, auth, analytics, messaging } from '../../auth/FirebaseAuth';

import axios from "axios";

export const AppViews = () => {
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  const history = useHistory();
  //getting current user uid
  useEffect(() => {
    auth.onAuthStateChanged(async function (user) {
      if (user) {
        analytics.logEvent('User_logged_in_siabulls', {
          content_type: 'json',
          content_id: new Date(),
          items: user
        });


        if(messaging != null){
        messaging.getToken({ vapidKey: 'BLnVc-SsjNwf3H6I9aNjLK-_tdem19fGf069glJfZDbAT3DXoFs0HmmEJj9mV-ZIWEt1FO3WYx6cKm1DNMDJ2A4' }).then((currentToken) => {
          if (currentToken) {
            console.log(currentToken);
            db.collection('users').doc(user.uid).collection('settings').doc('fcm').set({
              web: currentToken
            }, { merge: true });

          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });

        messaging.onMessage((payload) => {
          console.log('Message received. ', payload);
          notification.open({
            message: payload.notification.title,
            description: payload.notification.body,
            onClick: () => {
              console.log('Notification Clicked!');
              history.replace('/app/notifications');
            },
          });
          // ...
        })
      }

        // var a = 0;
        // setInterval(function(){ console.log('I am alive',a); a=a+1 }, 1000);

        // db.collection('users').where('uid', '==', user.uid).onSnapshot().then(async (querySnapshot) => {
        //   var count = 0;
        //   querySnapshot.forEach((doc) => {
        //     count = count + 1;
        //   })
        db.collection('users').doc(user.uid).onSnapshot((async (doc) => {
          if (doc.exists) {

            var userData = doc.data();
            console.log('Updated user doc recieved ', userData)

            if (!('accounts' in userData)) {
              userData['accounts'] = {};
            }
            if (!('liveMode' in userData)) {
              userData['liveMode'] = false;
            }

            localStorage.setItem('userData', JSON.stringify(userData));

            var initDefaultAccount;
            if (Object.keys(userData.accounts).length > 0) {
              var accounts = userData.accounts;
              for (var key in accounts) {
                if (accounts.hasOwnProperty(key)) {
                  if (accounts[key].defaultAccount == true) {
                    initDefaultAccount = key;
                  }
                }
              }
              localStorage.setItem('defaultAccount', initDefaultAccount);
            }

            if (userData.liveMode != JSON.parse(localStorage.getItem('userData')).liveMode) {
              window.location.reload()
            }

          }
          else {
            console.log('creating new doc as we donot have user doc')

            var accounts = {};
            var userObj = {
              displayName: user.displayName,
              email: user.email,
              phoneNumber: user.phoneNumber,
              photoURL: user.photoURL,
              providerId: user.providerId,
              uid: user.uid
            }
            await db.collection('users').doc(user.uid).set(userObj).then(() => {

              // var userData = userObj;
              // accounts = userObj.accounts
              userObj['accounts'] = {};
              userObj['liveMode'] = false;

              localStorage.setItem('userData', JSON.stringify(userObj));

            })
          }
        })
        )
      }
      else {
        console.log('no user');
        window.location.reload();
      }
    });



  }, [])

  var userData = JSON.parse(localStorage.getItem('userData'));
  const [userDataBool, setUserDataBool] = useState(false);
  // var userDataBool = false;
  var userInterval = 0;
  userInterval = setInterval(() => {
    userData = JSON.parse(localStorage.getItem('userData'));
    if (userData != null) {
      clearInterval(userInterval);
      console.log('Yay we got the user data')
      // userDataBool = true;
      setUserDataBool(true);
    }
    else {
      setUserDataBool(false);
      console.log('nope not yet')
    }
  }, 1000)

  return (
    <>
      {userDataBool == false ?
        <Spin />
        :
        <Suspense fallback={<Loading cover="content" />}>
          <Switch>
            <Route path={`${APP_PREFIX_PATH}/projects`} component={lazy(() => import(`./projects`))} />
            <Route path={`${APP_PREFIX_PATH}/databases`} component={lazy(() => import(`./databases`))} />
            <Route path={`${APP_PREFIX_PATH}/storage`} component={lazy(() => import(`./storage`))} />
            <Route path={`${APP_PREFIX_PATH}/applications`} component={lazy(() => import(`./applications`))} />
            <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile`))} />
            <Route path={`${APP_PREFIX_PATH}/billing`} component={lazy(() => import(`./billing`))} />
            <Route path={`${APP_PREFIX_PATH}/notifications`} component={lazy(() => import(`./notifications`))} />
            {/* {userData != null ?import history from '../../history';

        <> */}
            {userData.project ?
              <>{console.log('demo : ', userData.project)}
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
              </>
              :
              <>{console.log('live : ', userData.project)}
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/projects`} />
              </>
            }
            {/* </>
        :
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/demo/dashboards`} />
      } */}
          </Switch>
        </Suspense>
      }
    </>

  )


}

export default React.memo(AppViews);