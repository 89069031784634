import React from 'react'
import { Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { APP_NAME } from 'configs/AppConfig';
import { Link } from 'react-router-dom';
import Flex from 'components/shared-components/Flex';
import { useSelector } from 'react-redux';

const UnderMaintenance = () => {
	const theme = useSelector(state => state.theme.currentTheme)
	return (
		<div className={`h-100 ${theme === 'light' ? 'bg-white' : ''}`}>
			<div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
				<div>
					<img className="img-fluid" src={`/img/${theme === 'light' ? 'iotaxdark.png': 'iotaxlight.png'}`} width="200" alt="IOTAX" />
				</div>
				<div className="container">
					<Row align="middle">
						<Col xs={24} sm={24} md={8}>
							<h1 className="font-weight-bold mb-4 display-4">Maintenance</h1>
							<h2 className="font-weight-bold mb-4">Under Maintenance</h2>
							<p className="font-size-md mb-4">We are currently working on our Systems to improved and add new features, we will be back real soon.</p>
						</Col>
						<Col xs={24} sm={24} md={{ span: 14, offset: 2 }}>
							<img style={{borderRadius:'25px'}} className="img-fluid mt-md-0 mt-4" src="/img/maintenance.gif" alt="" />
						</Col>
					</Row>
				</div>
				<Flex mobileFlex={false} justifyContent="between">
					<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span></span>
				</Flex>
			</div>
		</div>
	)
}

export default UnderMaintenance
