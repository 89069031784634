import {
  AppstoreOutlined,
  HddOutlined,
  CloudServerOutlined,
  ApiOutlined,
  ClockCircleOutlined,
  HeatMapOutlined,
  ToolOutlined,
  ShopOutlined,
  SplitCellsOutlined,
  CreditCardOutlined,
  CodeOutlined,
  RocketOutlined,
  ReadOutlined,
  BarChartOutlined,
  FolderViewOutlined,
  SafetyCertificateOutlined,
  ClusterOutlined,
  ApartmentOutlined,
  DesktopOutlined,
  HourglassOutlined,
  BoxPlotOutlined,
  ProfileOutlined,
  FieldTimeOutlined,
  AppstoreAddOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const userData = JSON.parse(localStorage.getItem('userData'));

// Get Current Project
var CURRENT_PROJECT = (localStorage.getItem('currentProject')) ? JSON.parse(localStorage.getItem('currentProject')).name : 'Select Project';

//Project Selection Menu
const projectSelectionMenu = [{
  key: 'projectSelection',
  title: 'Project',
  breadcrumb: false,
  submenu: [
    {
      key: 'projectSelection-list',
      path: `${APP_PREFIX_PATH}/projects`,
      title: `${CURRENT_PROJECT}`,
      icon: HeatMapOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}];

// Development Menu
const developementMenu = [{
  key: 'development',
  path: `${APP_PREFIX_PATH}/development`,
  title: 'Development',
  icon: CodeOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'applications',
      path: `${APP_PREFIX_PATH}/applications`,
      title: 'Applications',
      icon: AppstoreOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'application-code',
          path: `${APP_PREFIX_PATH}/applications/code`,
          title: 'Code',
          icon: CodeOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'application-deployment',
          path: `${APP_PREFIX_PATH}/applications/deployment`,
          title: 'Deployment',
          icon: RocketOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'application-marketplace',
          path: `${APP_PREFIX_PATH}/applications/marketplace`,
          title: 'Marketplace',
          icon: ShopOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'application-documentation',
          path: `${APP_PREFIX_PATH}/applications/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'application-usage',
          path: `${APP_PREFIX_PATH}/applications/usages`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'storage',
      path: `${APP_PREFIX_PATH}/storage`,
      title: 'Storage',
      icon: CloudServerOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'storage-explorer',
          path: `${APP_PREFIX_PATH}/storage/explorer`,
          title: 'Explorer',
          icon: FolderViewOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'storage-security',
          path: `${APP_PREFIX_PATH}/storage/security`,
          title: 'Security',
          icon: SafetyCertificateOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'storage-documentation',
          path: `${APP_PREFIX_PATH}/storage/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'storage-usage',
          path: `${APP_PREFIX_PATH}/storage/usage`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'databases',
      path: `${APP_PREFIX_PATH}/databases`,
      title: 'Databases',
      icon: HddOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'database-explorer',
          path: `${APP_PREFIX_PATH}/database/explorer`,
          title: 'Explorer',
          icon: ClusterOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'database-security',
          path: `${APP_PREFIX_PATH}/database/security`,
          title: 'Security',
          icon: SafetyCertificateOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'database-documentation',
          path: `${APP_PREFIX_PATH}/database/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'database-usage',
          path: `${APP_PREFIX_PATH}/database/usage`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'apis',
      path: `${APP_PREFIX_PATH}/apis`,
      title: "API's",
      icon: ApiOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'api-code',
          path: `${APP_PREFIX_PATH}/api/code`,
          title: 'Code',
          icon: CodeOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'api-deployment',
          path: `${APP_PREFIX_PATH}/api/deployment`,
          title: 'Deployment',
          icon: RocketOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'api-explorer',
          path: `${APP_PREFIX_PATH}/api/explorer`,
          title: 'API Explorer',
          icon: ApartmentOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'api-marketplace',
          path: `${APP_PREFIX_PATH}/api/marketplace`,
          title: 'Marketplace',
          icon: ShopOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'api-documentation',
          path: `${APP_PREFIX_PATH}/api/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'api-usage',
          path: `${APP_PREFIX_PATH}/api/usages`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'crons',
      path: `${APP_PREFIX_PATH}/crons`,
      title: 'Cron Jobs',
      icon: FieldTimeOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'cron-code',
          path: `${APP_PREFIX_PATH}/cron/code`,
          title: 'Code',
          icon: CodeOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'cron-scheduler',
          path: `${APP_PREFIX_PATH}/cron/scheduler`,
          title: 'Scheduler',
          icon: HourglassOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'cron-documentation',
          path: `${APP_PREFIX_PATH}/cron/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'cron-usage',
          path: `${APP_PREFIX_PATH}/cron/usage`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    }
  ]
}];

// Analytics Menu
const analyticsMenu = [{
  key: 'analytics',
  path: `${APP_PREFIX_PATH}/analytics`,
  title: 'Analytics',
  icon: BarChartOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'monitor',
      path: `${APP_PREFIX_PATH}/monitor`,
      title: 'Monitor',
      icon: DesktopOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'monitor-apps',
          path: `${APP_PREFIX_PATH}/monitor/apps`,
          title: 'Apps Health',
          icon: AppstoreOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'monitor-apis',
          path: `${APP_PREFIX_PATH}/monitor/apis`,
          title: "API's Health",
          icon: ApiOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'monitor-crons',
          path: `${APP_PREFIX_PATH}/monitor/crons`,
          title: 'Crons Health',
          icon: ClockCircleOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'monitor-documentation',
          path: `${APP_PREFIX_PATH}/monitor/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'monitor-usage',
          path: `${APP_PREFIX_PATH}/monitor/usages`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'loggers',
      path: `${APP_PREFIX_PATH}/loggers`,
      title: 'Loggers',
      icon: AppstoreOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'loggers-overview',
          path: `${APP_PREFIX_PATH}/loggers/overview`,
          title: 'Overview',
          icon: BoxPlotOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'loggers-logs',
          path: `${APP_PREFIX_PATH}/loggers/logs`,
          title: "Logs",
          icon: ProfileOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'loggers-documentation',
          path: `${APP_PREFIX_PATH}/loggers/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'loggers-usage',
          path: `${APP_PREFIX_PATH}/loggers/usages`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    }
  ]
}];

const servicesMenu = [{
  key: 'services',
  path: `${APP_PREFIX_PATH}/services`,
  title: 'Services',
  icon: ApartmentOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'services-tools',
      path: `${APP_PREFIX_PATH}/services/tools`,
      title: 'Tools',
      icon: ToolOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'services-gateways',
      path: `${APP_PREFIX_PATH}/services/gateways`,
      title: 'Gateways',
      icon: SplitCellsOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}];

const marketplaceMenu = [{
  key: 'marketplace',
  path: `${APP_PREFIX_PATH}/marketplace`,
  title: 'Marketplace',
  icon: ShopOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'marketplace-overview',
      path: `${APP_PREFIX_PATH}/marketplace/overview`,
      title: 'Overview',
      icon: AppstoreAddOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'marketplace-subscriptions',
      path: `${APP_PREFIX_PATH}/marketplace/subscriptions`,
      title: 'Subscriptions',
      icon: CreditCardOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}];

const products = [{
  key: 'products',
  path: `${APP_PREFIX_PATH}/products`,
  title: 'Products',
  breadcrumb: false,
  submenu: [
    ...developementMenu,
    ...analyticsMenu,
    ...servicesMenu,
    ...marketplaceMenu
  ],
}];



var navigationConfig = []

if (userData != null) {
  if (localStorage.getItem('currentProject') != null) {
    navigationConfig = [
      ...projectSelectionMenu,
      ...products
      // ...developementMenu,
      // ...analyticsMenu,
      // ...servicesMenu,
      // ...marketplaceMenu
    ]
  } else {
    navigationConfig = [
      ...projectSelectionMenu
    ]
  }
}

// const navigationConfig = [
//   ...dashBoardNavTree,
//   ...tradingNavTree,
//   ...researchNavTree,
//   ...communityNavTree
// ]

export default navigationConfig;




/*
Project
    Current Project

Development
    Applications
        Code
        Deployment
        Marketplace
        Documentation
        Usage

    Storage
        File Explorer
        Security
        Documentation
        Usage
        
    Databases
        DB Explorer
        Credentials
        Documentation
        Usage

    API's
        Code
        Deployment
        API Explorer
        Marketplace
        Documentation
        Usage

    Cron Jobs
        Code
        Scheduler
        Documentation
        Usage

Analytics
    Monitor
        API Health
        APP Health
        Cron Jobs Health
        Documentation
        Usage

    Loggers
        overview
        logs
        Documentation
        Usage

Services
    Gateways
        Email
        Whatapp
        SMS
        Payment

    Tools
        Webhook Tester
        Regular Expression (https://github.com/gskinner/regexr)
        JSON Formatter
        File Diff
        Base64 Encoder/Decoder


Marketplace
    Apps
    Services
    APIs

Settings
    Profile
    Billing
        Overview
        Usage
        Payment
    Security
        2FA
        Password
        Delete Account
    Notifications
    Support
        Tickets
        Documentation
        FAQ
        Contact Us

Logout




*/